import style from '../styles/App.css';
import ProjectCard from '../components/projectCard';


export default function Projects() {

    const projects = [
        {name:"Song Database App", 
            img:'images/songImg.png', 
            tools:'Java, Regex, Advanced Data Structures', 
            link:'/#/songdatabaseproject'
        },
        {name:"Shortest Path Map Calculator", 
            img:'images/shortestPathImg.jpg', 
            tools:"Java, JUnit, JavaFX, Graphs, Djikstra's Shortest Path", 
          link:'/#/shortestpath'},
        {name:"Dungeons and Dragons Wiki", 
            img:'images/dndImg.jfif', 
            tools:'JavaScript, React, HTML, CSS, Bootstrap, 5E API', 
          link:'/#/d&dwiki'},
        {name:"Dungeons and Dragons Discord Bot", 
            img:'images/botImg.jpg', 
            tools:'Python, Discord.py, Beautiful Soup(extracting HTML), Pandas', 
          link:'/#/d&dbot'}
    ];

    return(
    <div class='container'>
        <div class='row' id='projectContainer'>
            <div class='col md-6'>
                {projects.filter((project) => (projects.indexOf(project) % 2 === 0)).map((project) => (<ProjectCard name={project.name} img={project.img} tools={project.tools} link={project.link}/>))}
            </div>

            <div class='col md-6'>
                {projects.filter((project) => (projects.indexOf(project) % 2 === 1)).map((project) => (<ProjectCard name={project.name} img={project.img} tools={project.tools} link={project.link}/>))}
            </div>

        </div>
</div>
    );
}
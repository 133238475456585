import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import style from '../styles/App.css';
import { useState, useEffect } from 'react';

export default function Project(props) {
    return(
        <div class='container' id='projectPage'>
            <div class='row'>
                <img id='projectImg' src={props.img}/>
                <h1>{props.name}</h1>
                <h5 class='text-muted'>{props.tools}</h5>
                <p>{props.desc}</p>
            </div>
        </div>
    );
}
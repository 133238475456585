import style from '../styles/App.css';
import { Link } from "react-router-dom";
import { useState } from 'react';

export default function Resume() {
    return(
        <div id='resume'> 
            <embed type='application/pdf' src='resume.pdf' width='90%' height='100%' />
        </div>
    );
}
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';
import style from '../styles/App.css';

export default function About() {
    return(
        <div>
            <img id='bascom' src='images/bascom.jpg'/>

            <p id='aboutText'>About me here</p>
        </div>
    );
}
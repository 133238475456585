import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import style from '../styles/App.css';
import { useState, useEffect } from 'react';

export default function ProjectCard(props){
    return(
        <div class='card'>
            <div class='card-body'>
                <img class='card-img-top' src={props.img}/>
                <h5 class='card-title'>{props.name}</h5>
                <h6 class='card-subtitle mb-2 text-muted'>{props.tools}</h6>
                <a class='btn btn-outline-secondary' href={props.link} role='button'>Read more!</a>
            </div>
        </div>
    );
}

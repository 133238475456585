import Classes from './pages/classes';
import About from './pages/about';
import Layout from './components/layout';
import Projects from './pages/projects';
import Project from './components/project';
import Resume from './pages/resume';
import { HashRouter as Router, Routes, Route} from 'react-router-dom';


function App() {

  const projects = [
    {name:"Song Database App", 
        img:'images/songImg.png', 
        tools:'Java, Regex, Advanced Data Structures', 
        desc:'An app dedicated to providing users with song selections based on various of inputs. Implemented using Java, this app uses Regex to systematically parse through a large CSV file and store its contents in a Red-Black Tree. This data structure allows for more optimal searches and quicker response times for maximum user satisfaction. My primary role in developing this project was the backend developer, and I worked for several weeks in parallel with a frontend developer to produce the finished product. The main purpose of this app is to allow users to retrieve songs that fit within their desired parameters quickly and efficiently.',
        link:'/songdatabaseproject'
    },
    {name:"Shortest Path Map Calculator", 
        img:'images/shortestPathImg.jpg', 
        tools:"Java, JUnit, JavaFX, Graphs, Djikstra's Shortest Path", 
        desc:'This app uses graphs and Dijkstra’s algorithm to quickly and accurately calculate the most optimal path between two locations. Implemented using Java, this project contains unit tests using JUnit to provide helpful and descriptive feedback about potential errors. JavaFX is used to create a simple and intuitive interface for users to input their locations and parameters. In addition to calculating the shortest route between two locations, this app allows users to add a third location they’d like to visit between the start and end. One feature also allows users to have average travel times between locations displayed along with their optimal routes. My primary role in developing this project was the frontend developer, and I worked for several weeks in parallel with a backend developer to produce the finished product.',
      link:'/shortestpath'},
    {name:"Dungeons and Dragons Wiki", 
        img:'images/dndImg.jfif', 
        tools:'JavaScript, React, HTML, CSS, Bootstrap, 5E API', 
        desc:'This website contains a vast amount of information about the Dungeons and Dragons 5th Edition system. Its most notable feature is its extensive spell list with several filtering options to help narrow the user’s selection. This project makes extensive use of HTML, React, and CSS to create a visually appealing and intuitive user interface. Bootstrap allowed for greater ease in the creation of a glamorous, yet functional, display and menu navigation system. Finally, using JavaScript in conjunction with the 5th Edition API, this website gathers and sorts large amounts of data to then be displayed to the user based on various filters. This tool’s primary function is to display relevant information to the user both to improve convenience and accessibility.',
      link:'/d&dwiki'},
    {name:"Dungeons and Dragons Discord Bot", 
        img:'images/botImg.jpg', 
        tools:'Python, Discord.py, Beautiful Soup(extracting HTML), Pandas', 
        desc:'This application is useable through Discord, a popular instant messaging software, to allow users to quickly and efficiently access information from a Dungeons and Dragons Wiki website. This project was developed in Python and combines the popular libraries BeatifulSoup and Pandas to extract HTML information and format it so that it can be sent through Discord. Users can make requests to the program from within Discord channels using specialized messages, and the previously formatted information can then be returned to the user using the Discord.py library. The primary purpose of this tool is to speed up the process of looking up information during virtual Dungeons and Dragons games, many of which use Discord as the main application for chatting.',
      link:'/d&dbot'}
];

//.map((project) => (<ProjectCard name={project.name} img={project.img} tools={project.tools} desc={project.desc}/>))

  return (
    <Router>
      <Routes>
        <Route element={<Layout/>}>
          <Route path='/classes' element={<Classes/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/resume' element={<Resume/>}/>
          <Route path='/' element={<Projects/>}/>

          {projects.map((project) => (<Route path={project.link} element={<Project name={project.name} img={project.img} tools={project.tools} desc={project.desc}/>}/>))}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

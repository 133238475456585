import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import style from '../styles/App.css';
import { useState, useEffect } from 'react';

export default function Class(props){

    

    const [showInfo, setShowInfo] = useState([])

    useEffect( () => {
        setShowInfo(false)
        }, []);

    if (showInfo === false) {
        return (
            <div class='card'>
                <div class='card-body'>
                    <h5 class='card-title' id='className' onClick={() => (setShowInfo(!showInfo))}>{props.name}</h5>
                </div>
            </div>
        );
    } else {
        return (
            <div class='card'>
                <div class='card-body'>
                    <h5 class='card-title' id='className' onClick={() => (setShowInfo(!showInfo))}>{props.name}</h5>
                    <p class='card-text'>{props.desc}</p>
                </div>
            </div>
        );
    }
}
import { Outlet } from "react-router-dom";
import style from '../styles/App.css';
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

export default function Layout() {
    return(
        <div class='container-fluid' id='body'>
            <div class='row justify-content-center vh-100 overflow-auto' id='homeBody'>
                <div class='col-md-8' id='homeMain'>

                    <div class='row-flex' id='titleCard'>
                    
                        <h1 id='name'>Noah Berg</h1>
                        <div id='icons'>
                            <a href='https://www.linkedin.com/in/noah-berg-79993b2b0/' target="_blank" rel='noopener'>
                                <FaLinkedin id='icon' size={50}/>
                            </a>
                            <a href="https://github.com/OpenBarrel" target='_blank' rel="noopener">
                                <FaGithub id='icon' size={50}/>
                            </a>
                        </div>
                    </div>
                    
                    <ul id='pages'>
                        <li className='page'> <Link to='/'>PROJECTS</Link> </li>
                        <li className='page'> <Link to='/classes'>CLASSES</Link> </li>
                        <li className='page'> <Link to='/resume'>RESUME</Link> </li>
                        {/*<li className='page'> <Link to='/about'>ABOUT ME</Link> </li> */}
                    </ul>
                        <Outlet/>
                </div>
            </div>
        </div>
    );
}